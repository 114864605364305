//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors 44BFEE​
// Primary
$primary: #EC375E;
$primary-active: #c52d57;
$primary-light: #fde5eb;
$primary-inverse: #ffffff;

// Success
$success: #01E299;
$success-active: #04aa77;
$success-light: #e1faee;
$success-inverse: #ffffff;

// Info -> Aero Azul
$info: #44BFEE;
$info-active: #0082c8;
$info-light: #e0f4fc;
$info-inverse: #ffffff;

// Danger -> Rosa Lavanda
$danger: #FF9EFF; 
$danger-active: #e82fea;
$danger-light: #ffe9ff;
$danger-inverse: #ffffff;

// Warning
$warning: #FF805A;
$warning-active: #fd470e;
$warning-light: #fce8e5;
$warning-inverse: #ffffff;

// Pink
$pink: #FF9EFF;
$pink-active: #e82fea;
$pink-light: #ffe9ff;
$pink-inverse: #ffffff;

$green: #122E33;
$green-active: #0c2024;
$green-light: #E7EAEA;
$green-inverse: #ffffff;
